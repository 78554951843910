import React from "react"
import type { PageProps } from "gatsby"

import { CustomLogo } from "../components/Headers/CustomLogo"
import { RedHalvaWithSovcombank } from "../components/Headers/Logos"

import Layout from "../components/Layouts/secondaryPage"
import Error from "../components/Error"

import type { ErrorPageProps } from "../components/Error"

export default function ErrorPage({ location }: PageProps<object, object, ErrorPageProps>) {
  const props = location.state

  return (
    <Layout>
      <CustomLogo withButton={false} Logo={RedHalvaWithSovcombank} />
      <Error {...props} />
    </Layout>
  )
}
